<template>
  <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit"></i> Add Broadcast
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1><i class="icon-arrow-up"></i></b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <b-row>
                    <b-col sm="3">
                      <TextBox v-model="item.title" placeholder="Title"
                               :addClass="{'is-invalid': $v.item.title.$error}">Title
                      </TextBox>
                    </b-col>
                    <b-col sm="3">
                      <Textarea v-model="item.content" placeholder="Content"
                               :addClass="{'is-invalid': $v.item.content.$error}">Content
                      </Textarea>
                    </b-col>
                    <b-col sm="4">
                      <h3>Image</h3>
                      <div class="profile-img-upload">
                        <img v-if="imgUrl" :src="imgUrl" alt="img" style="width: 150px">
                        <img v-else src="../../assets/img/no-image.png" alt="img" style="width: 150px">
                        <input type="file" style="display: none"
                               id="imgUpload"
                               v-on:input="onLogoChoose">
                        <label for="imgUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span style="cursor: pointer">Change</span>
                                            </span>
                        </label>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="d-flex justify-content-end">
                    <div class="form-actions">
                      <b-button right type="submit" variant="primary" @click="onSubmit">Sent</b-button>
                      <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/broadcast/list'">Cancel</router-link>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
  import {required} from 'vuelidate/lib/validators';

  export default {
    name: 'AddEditBroadcastPage',
    data() {
      return {
        item: {
          title: '',
          content: ''
        },
        selectedFile: null,
        imgUrl: ''
      }
    },
    created () {
     if (!this.$route.params.id || this.$route.params.id <= 0) {
        return
      }
      this.axios.get('public/broadcast/item', {params: {id: this.$route.params.id}})
          .then(response => {
            this.item = response.data.data
            if (this.item.image) {
              this.imgUrl = this.item.image.imageURL
            }

          })
          .catch(error => {
            if (error.response && error.response.data.status === 400) {
              this.$toastr.error(error.response.data.message)
            }
          })
    },
    methods: {
      onLogoChoose (event) {
        this.selectedFile = event.target.files[0]
        const formData = new FormData()
        formData.append('uploadFile', this.selectedFile)

        this.axios.post('public/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        )
            .then((response) => {
              if (response.data && response.data.data) {
                this.item.image = {id: response.data.data.id}
                this.imgUrl = response.data.data.imageURL
              }
            })
      },
      onSubmit() {
        if (this.$v.item.$invalid) {
          this.$v.$touch();
          return;
        }

        let axios
        if (this.item.id) {
          axios = this.axios.put('broadcast/item', this.item)
        } else {
          axios = this.axios.post('broadcast/item', this.item)
        }
        axios.then((res) => {
          if (res.data) {
            this.$toastr.info('Successfully saved');
            this.$router.push('/broadcast/list');
          } else {
            this.$toastr.error(res.data.message);
          }
        });
      }
    },
    validations: {
      item: {
        title: {
          required
        },
        content: {
          required
        }
      }
    }
  };
</script>

<style scoped>

</style>